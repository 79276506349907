import React, { useEffect } from 'react'
import EditorComponent from './Editor'
import InstructionPreview from './InstructionPreview'
import CreateLessonForm from './CreateLessonForm'
import { BackendConfigs } from '../../config.environment'

type Props = {}

function TeacherDataScienceEditorPage({ }: Props) {
    const [pythonCode, setPythonCode] = React.useState('')
    const [searchParams, setSearchParams] = React.useState(new URLSearchParams(window.location.search))
    const [lessonData, setLessonData] = React.useState<any>()
    async function getLessonData() {
        try {
            const lesson = await fetch(`${BackendConfigs.url}/coding-lesson-builder/get-lesson-by-lesson-id`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ lessonId: searchParams.get("lesson_id") })
            });
            const data = await lesson.json();
            if (data.status) {
                setLessonData(data.data);
                setPythonCode(data.data.lesson.pythonCode);
            }
        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {
        console.log(searchParams.get("lesson_id"));
        const lessonId = searchParams.get("lesson_id");
        if (lessonId) {
            getLessonData();
        }
    }, [searchParams])

    return (
        <div>
            <EditorComponent
                key={lessonData ? "edit" : "create"}
                initialPythonCode={pythonCode}
                setInitialPythonCode={setPythonCode}
                children={
                    <>
                        {
                            lessonData &&
                            <InstructionPreview lessonData={lessonData} />
                        }
                        <CreateLessonForm
                            key={lessonData ? "edit" : "create"}
                            pythonCode={pythonCode}
                            javaScriptCode={""}
                            htmlCode={""}
                            cssCode={""}
                            lessonData={lessonData}
                        />
                    </>
                }
            />
        </div>
    )
}

export default TeacherDataScienceEditorPage
import React, { useEffect } from 'react'
import EditorComponent from './Editor'
import CreateLessonForm from './CreateLessonForm'
import InstructionPreview from './InstructionPreview'
import { BackendConfigs } from '../../config.environment'
import SubmitLessonForm from './SubmitLessonForm'
import { LessonDataType } from '../../types/Lesson'
import { UserContext } from '../../context/UserContext'

type Props = {}

function ReviewWebEditorPage({ }: Props) {
    const { user } = React.useContext(UserContext);
    const [htmlCode, setHtmlCode] = React.useState('')
    const [cssCode, setCssCode] = React.useState('')
    const [javascriptCode, setJavascriptCode] = React.useState('')
    const [editors, setEditors] = React.useState<("html" | "css" | "javascript")[]>(["html", "css", "javascript"])
    const [searchParams, setSearchParams] = React.useState(new URLSearchParams(window.location.search))
    const [lessonData, setLessonData] = React.useState<LessonDataType>()
    async function getLessonData() {
        try {
            const lesson = await fetch(`${BackendConfigs.url}/coding-lesson-builder/get-lesson-by-lesson-id`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ lessonId: searchParams.get("lesson_id"), userId: searchParams.get("user_id") })
            });
            const data = await lesson.json() as {
                status: boolean,
                data: LessonDataType
            };
            if (data.status) {
                setLessonData(data.data);
                setHtmlCode(data.data.submission.htmlCode);
                setCssCode(data.data.submission.cssCode);
                setJavascriptCode(data.data.submission.javaScriptCode);
            }
        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {
        console.log(searchParams.get("lesson_id"));
        const lessonId = searchParams.get("lesson_id");
        if (lessonId && user.userData?.id) {
            getLessonData();
        }
    }, [searchParams, user])

    return (
        <div>
            <EditorComponent
                key={lessonData ? "edit" : "create"}
                initialHTMLCode={htmlCode}
                initialCSSCode={cssCode}
                initialJSCode={javascriptCode}
                editors={editors}
                setInitialHTMLCode={setHtmlCode}
                setInitialCSSCode={setCssCode}
                setInitialJSCode={setJavascriptCode}
                children={
                    <>
                        {
                            lessonData &&
                            <InstructionPreview lessonData={lessonData} />
                        }
                        {/* <SubmitLessonForm
                            key={lessonData ? "edit" : "create"}
                            pythonCode=""
                            javaScriptCode={javascriptCode}
                            htmlCode={htmlCode}
                            cssCode={cssCode}
                            lessonData={lessonData}
                        /> */}
                    </>
                }
            />
        </div>
    )
}

export default ReviewWebEditorPage
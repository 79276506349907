import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { routes } from './routes'
import Home from '../pages/Home'
import EditorPage from '../pages/HTMLEditor'
import TeacherEditorPage from '../pages/HTMLEditor/Teacher'
import StudentEditorPage from '../pages/HTMLEditor/Student'
import ReviewEditorPage from '../pages/HTMLEditor/Review'
import IDE from '../pages/PythonDataScience/Editor'
import TeacherDataScienceEditorPage from '../pages/PythonDataScience/Teacher'
import StudentDataScienceEditorPage from '../pages/PythonDataScience/Student'
import ReviewWebEditorPage from '../pages/HTMLEditor/Review'
import StudentDataScienceReviewPage from '../pages/PythonDataScience/Review'

function Router() {
    return (
        <Routes>
            <Route path={routes.ROOT} element={<EditorPage />} />
            <Route path={routes.TEACHER_SECTION} element={<TeacherEditorPage />} />
            <Route path={routes.STUDENT_SECTION} element={<StudentEditorPage />} />
            <Route path={routes.REVIEW_SECTION} element={<ReviewWebEditorPage />} />
            <Route path={routes.DATA_SCIENCE_TEACHER_SECTION} element={<TeacherDataScienceEditorPage />} />
            <Route path={routes.DATA_SCIENCE_STUDENT_SECTION} element={<StudentDataScienceEditorPage />} />
            <Route path={routes.DATA_SCIENCE_REVIEW_SECTION} element={<StudentDataScienceReviewPage />} />
            <Route path={routes.NOTFOUND} element={<div>Not Found</div>} />
            <Route path={routes.WILDCARD} element={<Navigate to={routes.NOTFOUND} replace={true} />} />
        </Routes>
    )
}

export default Router
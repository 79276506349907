import { Dispatch, createContext, useEffect, useReducer } from "react"
import { getCookieUserData, removeCookieUserData, setCookieUserData } from "../services/CookieStorage/CookieAuthentication";
import { getLocalUserData, removeLocalUserData, setLocalUserData } from "../services/LocalStorage/LocalAuthentication";
import { BackendConfigs, MainConfigs } from "../config.environment";
import { APIResponse } from "../types/Response";

export type userStateType = {
    isLogin: boolean,
    userData: {
        id: string | null,
        name: string | null,
        contact: {
            type: "email" | "phone",
            value: string
        } | null,
        avatar: string | null,
        role: string | null,
        token: string | null,
        schoolId?: string | null
    } | null
}

type userActionType = {
    type: "login" | "logout" | "update",
    value: {
        userData: userStateType["userData"] | null,
    }
}

export const userInitialState: userStateType = {
    isLogin: false,
    userData: null
}

function userReducer(state: userStateType, action: userActionType): userStateType {
    const user = {
        id: action.value.userData?.id as string,
        name: action.value.userData?.name as string,
        contact: action.value.userData?.contact as { type: "email" | "phone", value: string },
        role: action.value.userData?.role as string,
        token: action.value.userData?.token as string,
        avatar: action.value.userData?.avatar as string,
        schoolId: action.value.userData?.schoolId as string
    }
    console.log(user);
    switch (action.type) {
        case "login":
            setLocalUserData(user)
            setCookieUserData(user)
            return { ...state, isLogin: true, userData: action.value.userData || null }
        case "logout":
            removeCookieUserData()
            removeLocalUserData()
            return { ...state, isLogin: false, userData: action.value.userData || null }
        case "update":
            setLocalUserData(user)
            setCookieUserData(user)
            return { ...state, isLogin: true, userData: action.value.userData || null }
        default:
            return state
    }
}

export const UserContext = createContext({
    user: userInitialState,
    changeUser: ((() => { }) as Dispatch<userActionType>)
})


export async function getUserDataAfterVerification(userID: string) {
    try {
        const response = await fetch(`https://backend.innovator.educobot.com/GetUserDataAPI`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId: userID })
        })
        if (response.status === 200) {
            const data = await response.json() as APIResponse
            console.log(data);
            return data.data
        }
        return false
    } catch (error) {
        console.log(error);
        return false
    }
}




export default function UserContextProvider(props: {
    children: React.ReactNode
}) {
    const [user, changeUser] = useReducer(userReducer, userInitialState)

    async function checkUser() {
        const cookie = await getCookieUserData()
        console.log(cookie);

        const local = getLocalUserData()
        if (cookie) {
            changeUser({ type: "login", value: { userData: cookie, } })
        }
        else if (local) {
            changeUser({ type: "login", value: { userData: local } })
        }
        else {
            changeUser({ type: "logout", value: { userData: null } })
            window.location.href = 'https://educobot.com/auth/login'
        }
    }
    useEffect(() => {
        // if (MainConfigs.NODE_ENVIRONMENT === "development" ) {
        //     changeUser({
        //         type: "login", value: {
        //             userData: {
        //                 id: "6547bbc9-6964-412e-a4e4-3ac1423a0a06",
        //                 name: "Shivkumar Singh Chauhan",
        //                 contact: {
        //                     type: "email",
        //                     value: "shivkumar@educobot.com"
        //                 },
        //                 avatar: null,
        //                 role: "Teacher",
        //                 token: null,
        //                 schoolId: "7beec1a9-3a77-467a-8a12-7b1296746b9d"
        //             },
        //         }
        //     })
        // } else {
            checkUser()
        // }
    }, [])
    return (
        <UserContext.Provider value={{ user, changeUser }}>
            {props.children}
        </UserContext.Provider>
    )
}

import React, { useContext, useEffect, useState } from 'react'
import { SettingContext } from '../../../context/SettingsContext'
import { Button, Card, Stack, ToggleButton } from '@mui/material'
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import HomeIcon from '@mui/icons-material/Home';
import { uploadFileOnS3 } from '../../../services/S3Storage/Upload';
import { toast } from 'react-toastify';
import { FileUploader } from "react-drag-drop-files";
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import { ContentDialogBox } from '../../../components/dialog_box';
type Props = {
  children?: React.ReactNode
}
const fileTypes = ["CSV", "XLSX"];

function IDEHeader({ children }: Props) {

  const { settings, toggleTheme } = useContext(SettingContext)
  return (
    <div style={{
      padding: '2px 10px',
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    }}>

      <h3 style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        Python Data Science IDE
      </h3>
      <Stack direction={'row'} alignItems={"center"} spacing={2}>
        {
          children
        }
        <FileStorageMapper />
        <ToggleButton
          style={{ borderRadius: "50px", border: "none" }}
          value="check"
          onChange={toggleTheme}
        >
          {settings.themeMode === "dark" && <LightModeIcon color={"primary"} />}
          {settings.themeMode === "light" && <DarkModeIcon color={"primary"} />}
        </ToggleButton>

      </Stack>
    </div>
  )
}

export default IDEHeader



function FileStorageMapper() {
  const [isOpen, setIsOpen] = useState(false)
  const [isFileWindowOpen, setIsFileWindowOpen] = React.useState(true)
  const [File, setFile] = React.useState<string | null>(null)
  const [filesList, setFilesList] = React.useState<{
    name: string,
    url: string
  }[]>([])
  useEffect(() => {
    const array = localStorage.getItem("filesList")
    if (array) {
      setFilesList(JSON.parse(array))
    }
  }, [])
  const handleChange = async (file: File) => {
    if (!file) return
    if (!!file) {
      try {
        const response = await uploadFileOnS3(file, file.name.split(".")[0]) as {
          location: string,
          bucket: string,
          key: string
        };
        console.log(response);
        if (!!response.location) {
          setFile(response.location)
          const newArray = [
            ...filesList,
            {
              name: file.name,
              url: response.location
            }
          ]
          setFilesList((prev) => {
            return newArray
          })
          localStorage.setItem("filesList", JSON.stringify(newArray))
          setIsFileWindowOpen(false)
          toast.info("File uploaded successfully")
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <Button
        onClick={() => setIsOpen(true)}
        variant="contained"
        color="primary"
        startIcon={<DatasetLinkedIcon />}
        style={{
          margin: "10px"
        }}
      >
        Resources
      </Button>
      <ContentDialogBox
        title={"Dataset"}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        content={
          <>
            <div>
              <div style={{
                margin: "10px 0",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}>
                <p>Upload your dataset and get link </p>


                <FileUploader multiple={false} handleChange={handleChange} name="file" types={fileTypes} style={{ width: "min(500px,90%)" }} classes="file_uploader" />

              </div>
            </div>
            <div>
              {
                filesList.map((file, index) => {
                  return (
                    <Card style={{
                      width: "100%",
                      padding: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }} key={index}>
                      <a href={file.url} target="_blank">{file.name}</a>
                      <Button variant='outlined' onClick={() => {
                        navigator.clipboard.writeText(file.url)
                        toast.info("Link copied to clipboard")
                      }}>
                        Copy Link
                      </Button>
                    </Card>
                  )
                })
              }
            </div>
          </>
        }
      />


    </div>
  )
}
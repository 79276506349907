import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import { ContentDialogBox } from '../../components/dialog_box'
import DeleteIcon from '@mui/icons-material/Delete';
import { uploadFileOnS3 } from '../../services/S3Storage/Upload';
import { FileUploader } from "react-drag-drop-files";
import { toast } from 'react-toastify';
import { UserContext } from '../../context/UserContext';
import { routes } from '../../routes/routes';
import { LessonDataType } from '../../types/Lesson';
import { BackendConfigs } from '../../config.environment';

type Props = {
    pythonCode: string,
    javaScriptCode: string,
    htmlCode: string,
    cssCode: string,
    lessonData?: LessonDataType
}

function SubmitLessonForm({
    pythonCode,
    javaScriptCode,
    htmlCode,
    cssCode,
    lessonData
}: Props) {
    const [isOpen, setIsOpen] = React.useState(false)
    const { user } = React.useContext(UserContext)
    const [isSubmitted, setIsSubmitted] = React.useState(lessonData?.isSubmitted || false)

    async function SubmitLessonForm(isSubmitted: boolean = false) {
        try {
            console.log({
                htmlCode,
                cssCode,
                javaScriptCode,
                pythonCode,
                userId: user.userData?.id,
                lessonId: lessonData?.lesson.lessonId
            });
            const response = await fetch(`${BackendConfigs.url}/coding-lesson-builder/save-coding-lesson`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    htmlCode,
                    cssCode,
                    javaScriptCode,
                    pythonCode,
                    userId: user.userData?.id,
                    lessonId: lessonData?.lesson.lessonId,
                    isSubmitted: !!isSubmitted,
                })
            })
            const data = await response.json()
            console.log(data)
            if (data.status === true) {
                if (!!isSubmitted) {
                    // setTimeout(() => {
                        window.location.reload()

                    // }, 2000)
                } else {
                    toast.success("Lesson saved successfully")
                    setIsOpen(false)
                }
            } else {
                toast.error("Failed to create lesson")
            }


        } catch (error) {
            console.log(error);

        }
    }















    return (
        <>
            {
                !isSubmitted ? <> <Button variant="contained" color="secondary" sx={{
                    textTransform: "none",
                    margin: "0 5px"
                }}
                    onClick={() => {
                        SubmitLessonForm()
                    }}
                >
                    Save
                </Button>
                    <Button variant="contained" color="primary" sx={{
                        textTransform: "none"
                    }}
                        onClick={() => setIsOpen(true)}
                    >
                        Submit
                    </Button>
                </> : lessonData?.isReviewed ?
                    <Button variant="contained" color="success" onClick={() => setIsOpen(true)}>
                        Reviewed
                    </Button> :
                    <Button variant="contained" color="info" onClick={() => setIsOpen(true)}>
                        Submitted
                    </Button>
            }
            <ContentDialogBox
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                maxWidth="md"
                content={
                    <div>
                        {
                            isSubmitted ? <>
                                {
                                    lessonData?.isReviewed ? <>

                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            gap: "20px"
                                        }}>
                                            <h3 style={{
                                                color: "#1976d2"
                                            }}>Your Assignment is Reviewed</h3>
                                            <img src="https://cdn-icons-png.flaticon.com/512/2191/2191108.png" alt="" style={{
                                                maxWidth: "250px"
                                            }} />
                                            <p>You have been awarded <b>{lessonData?.submission.coins}</b> coins</p>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "10px"
                                            }}>

                                                {
                                                    lessonData && [0, 0, 0].map((item, index) => {
                                                        return index < lessonData?.submission.coins ?
                                                            <div key={index}>
                                                                <img src={"https://storage.googleapis.com/open-editor-assets/website-statics/100-coin.png"} alt="" style={{
                                                                    cursor: "pointer",
                                                                    width: "50px",
                                                                    height: "50px",
                                                                    margin: "10px",

                                                                }} />
                                                            </div> : <div key={index}>
                                                                <img src={"https://storage.googleapis.com/open-editor-assets/website-statics/0-coin.png"} alt="" style={{
                                                                    cursor: "pointer",
                                                                    width: "50px",
                                                                    height: "50px",
                                                                    margin: "10px",
                                                                }} />
                                                            </div>
                                                    })
                                                }
                                            </div>

                                        </div>
                                    </> : <>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            gap: "20px"
                                        }}>
                                            <h3 style={{
                                                color: "#1976d2"
                                            }}>Your Assignment is Submitted</h3>
                                            <img src="https://cdn-icons-png.flaticon.com/512/17877/17877365.png" alt="" style={{
                                                maxWidth: "250px"
                                            }} />
                                        </div>

                                    </>
                                }

                            </> : <>

                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    gap: "20px"
                                }}>
                                    <h3 style={{
                                        color: "#1976d2"
                                    }}>Final Submission Alert</h3>
                                    <img src="https://cdn-icons-png.freepik.com/256/3143/3143361.png?semt=ais_hybrid" alt="" style={{
                                        maxWidth: "250px"
                                    }} />
                                    <p>Once you submit, this lesson will no longer be editable. Please review carefully before proceeding</p>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        style={{ margin: '20px auto', backgroundColor: '#1976d2', color: '#fff', width: "280px", textTransform: "none", }}
                                        onClick={() => {
                                            SubmitLessonForm(true)
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </>
                        }
                    </div>
                }
            />
        </>
    )
}

export default SubmitLessonForm
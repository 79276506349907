import React, { useEffect } from 'react'
import EditorComponent from './Editor'
import InstructionPreview from './InstructionPreview'
import { BackendConfigs } from '../../config.environment'
import SubmitLessonForm from './SubmitLessonForm'
import { LessonDataType } from '../../types/Lesson'
import { UserContext } from '../../context/UserContext'

type Props = {}

function StudentDataScienceEditorPage({ }: Props) {
    const { user } = React.useContext(UserContext);
    const [pythonCode, setPythonCode] = React.useState('')

    const [searchParams, setSearchParams] = React.useState(new URLSearchParams(window.location.search))
    const [lessonData, setLessonData] = React.useState<LessonDataType>()
    async function getLessonData() {
        try {
            const lesson = await fetch(`${BackendConfigs.url}/coding-lesson-builder/get-lesson-by-lesson-id`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ lessonId: searchParams.get("lesson_id"), userId: user.userData?.id })
            });
            const data = await lesson.json() as {
                status: boolean,
                data: LessonDataType
            };
            if (data.status) {
                setLessonData(data.data);
                setPythonCode(data.data.submission.pythonCode || data.data.lesson.pythonCode);
            }
        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {
        console.log(searchParams.get("lesson_id"));
        const lessonId = searchParams.get("lesson_id");
        if (lessonId && user.userData?.id) {
            getLessonData();
        }
    }, [searchParams, user])

    return (
        <div>
            {
                lessonData &&
                <EditorComponent
                    initialPythonCode={pythonCode}
                    setInitialPythonCode={setPythonCode}
                    children={
                        <>
                            {
                                lessonData &&
                                <InstructionPreview lessonData={lessonData} />
                            }
                            <SubmitLessonForm
                                key={lessonData ? "edit" : "create"}
                                pythonCode={pythonCode}
                                javaScriptCode={""}
                                htmlCode={""}
                                cssCode={""}
                                lessonData={lessonData}
                            />
                        </>
                    }
                />
            }
        </div>
    )
}

export default StudentDataScienceEditorPage
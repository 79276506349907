export const routes={
    ROOT:'/',
    HTMLEditor:"/web-editor",
    TEACHER_SECTION:"/teacher/create",
    STUDENT_SECTION:"/web/student/attempt",
    REVIEW_SECTION:"/web/teacher/review",
    DATA_SCIENCE_TEACHER_SECTION:"/data-science/teacher/create",
    DATA_SCIENCE_STUDENT_SECTION:"/data-science/student/attempt",
    DATA_SCIENCE_REVIEW_SECTION:"/data-science/teacher/review",
    NOTFOUND:'/404',
    WILDCARD:"*"
}
import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import { ContentDialogBox } from '../../components/dialog_box'
import { LessonDataType } from '../../types/Lesson'

type Props = {
    lessonData?: LessonDataType

}

function InstructionPreview({ lessonData }: Props) {
    const [isOpen, setIsOpen] = React.useState(true)
    useEffect(() => {
        console.log('instruction preview', lessonData)
    }, [lessonData])
    return (
        <>
            <Button onClick={() => setIsOpen(!isOpen)}>
                <img src="https://webapp.educobot.com/assets/Help_new.png" style={{
                    width: 30,
                    height: 30,
                    cursor: 'pointer'
                }} alt="" />
            </Button>
            <ContentDialogBox
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title="Preview"
                DialogContainerStyle={{
                    borderRadius: "30px",
                    background: "#EE9322",
                    color: "#80000B",
                    fontweight: "bolder",
                    textAlign: "center"
                }}
                maxWidth="xs"
                content={
                    <div>
                        <hr style={{ border: "1px solid #80000B" }} />
                        <h3>{
                            lessonData && lessonData?.lesson.lessonName
                        }</h3>
                        <hr style={{ border: "1px solid #80000B" }} />

                        <p>
                            {
                                lessonData && lessonData.lesson.description
                            }
                        </p>
                        <hr style={{ border: "1px solid #80000B" }} />
                        {
                            lessonData&&lessonData.lesson.instructions.map((instruction, index) => {
                                return (
                                    <div key={index}>
                                        <h4 key={index} style={{
                                            padding: "10px",
                                            margin: "5px",
                                            textAlign: "left",
                                            display: "flex"
                                        }}>
                                            <span>{index + 1}. </span> {instruction}
                                        </h4>
                                        <hr style={{ border: "1px solid #80000B" }} />
                                    </div>
                                );
                            })
                        }
                    </div>
                }
            />

        </>
    )
}

export default InstructionPreview
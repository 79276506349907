const PORT = process.env.PORT || 3000;
const NODE_ENVIRONMENT =  "production"

const DEPLOYMENT_TYPE = "production" 
const BackendURL = DEPLOYMENT_TYPE === "production" ? "https://backend.educobot.com" : process.env.DEPLOYMENT_TYPE === "testing" ? "https://testing.com" : "http://localhost:3001"

export const MainConfigs = {
    NODE_ENVIRONMENT,
    DEPLOYMENT_TYPE,
}


export const BackendConfigs = {
    url: BackendURL,
    apis: {
        auth: {

        },
        users: {

        }
    }
};


export const SiblingConfigs = {
    sibling1: {
        url: ``,
        paths: {

        }
    }
}

export const ChildrenConfigs = {
    child1: {
        url: ``,
        paths: {

        }
    }
}

export const ParentConfigs = {
    url: ``,
    paths: {

    }
}



export const ServicesConfigs = {
    payment: {

    },
    storage: {

    }
}
import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import { ContentDialogBox } from '../../components/dialog_box'
import DeleteIcon from '@mui/icons-material/Delete';
import { uploadFileOnS3 } from '../../services/S3Storage/Upload';
import { FileUploader } from "react-drag-drop-files";
import { toast } from 'react-toastify';
import { UserContext } from '../../context/UserContext';
import { routes } from '../../routes/routes';
import { LessonDataType } from '../../types/Lesson';
import { BackendConfigs } from '../../config.environment';

type Props = {
    pythonCode: string,
    javaScriptCode: string,
    htmlCode: string,
    cssCode: string,
    lessonData?: LessonDataType
}
const fileTypes = ["JPEG", "PNG", "JPG", "SVG", "WEPG"];

function CreateLessonForm({
    pythonCode,
    javaScriptCode,
    htmlCode,
    cssCode,
    lessonData
}: Props) {
    const [lessonName, setLessonName] = React.useState(lessonData?.lesson.lessonName || '')
    const [lessonDescription, setLessonDescription] = React.useState(lessonData?.lesson.description || '')
    const [lessonType, setLessonType] = React.useState(lessonData?.lesson.lessonType || '')
    const [lessonThumbnail, setLessonThumbnail] = React.useState(lessonData?.lesson.thumbnailURL || '')
    const [codingLanguages, setCodingLanguages] = React.useState<string[]>(lessonData?.lesson.codingLanguages || [])
    const [instructions, setInstructions] = React.useState<string[]>(lessonData?.lesson.instructions || [])
    const [isOpen, setIsOpen] = React.useState(false)
    const { user } = React.useContext(UserContext)
    const handleCodingLanguagesChange = (event: any) => {
        setCodingLanguages(event.target.value);
    };

    const handleInstructionChange = (index: number, value: string) => {
        const newInstructions = [...instructions];
        newInstructions[index] = value;
        setInstructions(newInstructions);
    };

    const addInstruction = () => {
        setInstructions([...instructions, '']);
    };

    const removeInstruction = (index: number) => {
        const newInstructions = instructions.filter((_, i) => i !== index);
        setInstructions(newInstructions);
    };
    const handleChange = async (file: File) => {
        if (!file) return
        if (!!file) {
            try {
                const response = await uploadFileOnS3(file, file.name.split(".")[0]) as {
                    location: string,
                    bucket: string,
                    key: string
                };
                console.log(response);
                if (!!response.location) {
                    setLessonThumbnail(response.location)
                }
            } catch (error) {
                console.log(error);
            }
        }
    };


    async function createLesson(event: any) {
        event.preventDefault()
        if (!lessonName) {
            toast.error("Please enter lesson name")
            return
        }
        if (!lessonDescription) {
            toast.error("Please enter lesson description")
            return
        }
        if (!lessonType) {
            toast.error("Please select lesson type")
            return
        }
        try {
            console.log({
                lessonName,
                description: lessonDescription,
                lessonType,
                lessonThumbnail,
                codingLanguages,
                instructions,
                htmlCode,
                cssCode,
                javaScriptCode,
                pythonCode,
                teacherId: user.userData?.id,
                lessonId: lessonData?.lesson.lessonId
            });

            const response = await fetch(`${BackendConfigs.url}/coding-lesson-builder/create-data-science-lesson`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    lessonName,
                    description: lessonDescription,
                    lessonType,
                    lessonThumbnail,
                    codingLanguages,
                    instructions,
                    htmlCode,
                    cssCode,
                    javaScriptCode,
                    pythonCode,
                    teacherId: user.userData?.id,
                    lessonId: lessonData?.lesson.lessonId
                })
            })
            const data = await response.json()
            console.log(data)
            if (data.status === true) {
                if (!lessonData) {
                    toast.success("Lesson created successfully")
                    window.location.href = routes.DATA_SCIENCE_TEACHER_SECTION + "?lesson_id=" + data.data.lessonId
                }else{
                    toast.success("Lesson Updated successfully")
                }
                setIsOpen(false)
            } else {
                toast.error("Failed to create lesson")
            }


        } catch (error) {
            console.log(error);

        }




    }















    return (
        <>
            <Button variant="contained" color="primary" sx={{
                textTransform: "none"
            }}
                onClick={() => setIsOpen(true)}
            >
                {
                    lessonData ? "Edit Lesson" : "Create Lesson"
                }
            </Button>
            <ContentDialogBox
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                maxWidth="md"
                content={
                    <div>
                        {/* <Typography variant="h4" component="h1" gutterBottom>
                                    Create Lesson
                                </Typography> */}

                        <TextField
                            label="Lesson Name"
                            value={lessonName}
                            onChange={(e) => setLessonName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            style={{ margin: '5px 0' }}
                        />
                        <TextField
                            label="Lesson Description"
                            value={lessonDescription}
                            onChange={(e) => setLessonDescription(e.target.value)}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            style={{ margin: '5px 0' }}
                        />
                        <FormControl component="fieldset" style={{ margin: '16px 0', width: '100%' }}>
                            <Typography component="legend">Lesson Type</Typography>
                            <RadioGroup
                                value={lessonType}
                                onChange={(e) => setLessonType(e.target.value)}
                                row
                            >
                                <FormControlLabel value="practice" control={<Radio />} label="Practice" />
                                <FormControlLabel value="test" control={<Radio />} label="Test" />
                                <FormControlLabel value="project" control={<Radio />} label="Project" />
                            </RadioGroup>
                        </FormControl>
                        <div style={{
                            margin: "10px 0",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}>
                            <p>Upload your Lesson Thumbnail </p>


                            <FileUploader multiple={false} handleChange={handleChange} name="file" types={fileTypes} style={{ width: "min(500px,90%)" }} classes="file_uploader" />
                            {
                                lessonThumbnail && <img src={lessonThumbnail} alt="thumbnail" style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                    // borderRadius: "50%",
                                    margin: "10px 0"
                                }} />
                            }

                        </div>
                        {/* <FormControl variant="outlined" fullWidth style={{ margin: '5px 0' }}>
                            <InputLabel>Coding Languages</InputLabel>
                            <Select
                                multiple
                                value={codingLanguages}
                                onChange={handleCodingLanguagesChange}
                                input={<OutlinedInput label="Coding Languages" />}
                                renderValue={(selected) => (selected as string[]).join(', ')}
                            >
                                {['HTML', 'CSS', 'JavaScript'].map((language) => (
                                    <MenuItem key={language} value={language}>
                                        <Checkbox checked={codingLanguages.indexOf(language) > -1} />
                                        <ListItemText primary={language} />
                                    </MenuItem>
                                ))}
                            </Select>
                            <p style={{
                                fontSize: "12px",
                                color: "gray"
                            }}>*Student will see only these editor only which you select</p>
                        </FormControl> */}
                        <Box>
                            <Typography variant="h6" gutterBottom>
                                Instructions
                            </Typography>
                            {instructions.map((instruction, index) => (
                                <Grid container spacing={2} key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                    <Grid item xs={11}>
                                        <TextField
                                            label={`Instruction ${index + 1}`}
                                            value={instruction}
                                            onChange={(e) => handleInstructionChange(index, e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            onClick={() => removeInstruction(index)}
                                            aria-label="delete"
                                            color="secondary"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={addInstruction}
                                style={{ margin: '5px 0' }}
                            >
                                Add Instruction
                            </Button>
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                            style={{ margin: '5px 0', backgroundColor: '#1976d2', color: '#fff' }}
                            onClick={createLesson}
                        >
                            Submit
                        </Button>








                    </div>
                }
            />
        </>
    )
}

export default CreateLessonForm